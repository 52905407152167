import React from 'react'
import Title from '../title'
import { Container, P, Button } from './styles'

const ContentBlock = (props) => (
  <Container {...props}>
    {props.titleText && <Title text={props.titleText} variant="h2" centered />}
    {props.paragraphs.map((text, index) => <P key={index}>{text}</P>)}
    {props.buttonHref && props.buttonText && <Button to={props.buttonHref}>{props.buttonText}</Button>}
  </Container>
)

export default ContentBlock
