import React from 'react'
import MainLayout from '../layouts/main-layout'
import SEO from '../components/seo'
import CTA from '../components/cta'
import ContentBlock from '../components/content-block'
import ImageBlock from '../components/image-block'
import image from '../images/gallery/7.jpg'

const IndexPage = () => (
  <MainLayout subtitleText="Suppliers and fitters of quality carpets and vinyls" headerCta={{ text: "Contact Us", to: "/contact" }}>
    <SEO title="Home" />

    <ContentBlock
      titleText="Welcome to Colin Cooke Carpets"
      paragraphs={[
        'We know how important choosing the right flooring is to you. As professional carpet and vinyl fitters, we have over 60 years of experience to help you make this decision. Our hard earned reputation for quality and value means we can accommodate your requirements and budget. From a small bathroom to a large classroom and more, we can cater for your domestic and commercial needs.'
      ]}
      buttonText="Read more about us >"
      buttonHref="/about"
    />

    <ImageBlock src={image} />

    <ContentBlock
      titleText="The shop that comes to you!"
      paragraphs={[
        'We offer a mobile shop service which we bring to you on a free home visit. Our mobile shop is equipped with all leading brands in a range of different styles and colours. If nothing suits your needs, we can explore other options with you.',
        'With plenty of samples to look through, we will help you choose your perfect new floor, and put together a free quotation for you. You can also take inspiration from our gallery which showcases a selection of our previous projects.'
      ]}
      buttonText="View our work >"
      buttonHref="/gallery"
    />

    <CTA
      titleText="Book your free home visit now"
      buttonTo="/contact"
      buttonText="Contact Us"
    />
  </MainLayout>
)

export default IndexPage
