import styled from 'styled-components'
import { transparentize } from 'polished'

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  /* display: flex; */
  position: relative;
`

export const Img = styled.img`
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #BF2D38;
  background-image: url('${props => props.src}');
  background-image: linear-gradient(to top, ${transparentize(0.9, '#000000')}, ${transparentize(0.9, '#000000')}), url('${props => props.src}');
  background-size: cover;
  background-position: top;
`

// export const Container = styled.div`
//   padding: 1rem;
//
//   ${breakpoint('desktop')`
//     padding: 5rem;
//   `}
//
//   margin: 0;
//   display: flex;
//   flex-direction: column;
//   background-color: white;
//
//   ${props => props.grey && css`
//     background-color: #EFEFEF;
//   `}
// `
