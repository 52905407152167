import styled, { css } from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { shade } from 'polished'
import { Link } from 'gatsby'

export const Container = styled.div`
  padding: 2rem 1rem;

  ${breakpoint('tablet')`
    padding: 3rem 2rem;
  `}

  ${breakpoint('desktop')`
    padding: 6rem;
  `}

  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: white;

  ${props => props.grey && css`
    background-color: #EFEFEF;
  `}
`

export const P = styled.p`
  color: #1F1F1F;
`

export const Button = styled(Link)`
  color: ${shade(0.5, '#BF2D38')};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  margin-top: 1rem;
  align-self: center;

  &:hover {
    text-decoration: underline;
  }

  ${breakpoint('tablet')`
  text-align: right;
  align-self: flex-end;
  `}
`
