import React from 'react'
import { Container, ImgContainer } from './styles'

const ImageBlock = ({ src }) => (
  <Container>
    <ImgContainer src={src} />
  </Container>
)

export default ImageBlock
